const Holidaydata = () => {
    return {
      "version": 3,
      "title": "Holidays and Observances in Myanmar in 2025",
      "holidays": [
        {
          "name": "New Year's Day",
          "types": "National Holiday",
          "day": "Wednesday",
          "date": "1 January 2025",
          "comments": "--"
        },
        {
          "name": "Independence Day",
          "types": "National Holiday",
          "day": "Saturday",
          "date": "4 January 2025",
          "comments": "--"
        },
        {
          "name": "Chinese New Year",
          "types": "National Holiday",
          "day": "Wednesday",
          "date": "29 January 2025",
          "comments": "--"
        },
        {
          "name": "Union Day",
          "types": "National Holiday",
          "day": "Wednesday",
          "date": "12 February 2025",
          "comments": "--"
        },
        {
          "name": "Peasants' Day",
          "types": "National Holiday",
          "day": "Sunday",
          "date": "2 March 2025",
          "comments": "--"
        },
        {
          "name": "Day before Full Moon of Taboung",
          "types": "National Holiday",
          "day": "Wednesday",
          "date": "12 March 2025",
          "comments": "Date varies depending on the lunar cycle"
        },
        {
          "name": "Full Moon Day of Tabaung",
          "types": "National Holiday",
          "day": "Thursday",
          "date": "13 March 2025",
          "comments": "Date varies depending on the lunar cycle"
        },
        {
          "name": "Day after Full Moon of Taboung",
          "types": "National Holiday",
          "day": "Friday",
          "date": "14 March 2025",
          "comments": "Date varies depending on the lunar cycle"
        },
        {
          "name": "Armed Forces Day",
          "types": "National Holiday",
          "day": "Thursday",
          "date": "27 March 2025",
          "comments": "Tatmadaw Day"
        },
        {
          "name": "Thingyan",
          "types": "National Holiday",
          "day": "Sunday",
          "date": "13 April 2025",
          "comments": "Water festival"
        },
        {
          "name": "Thingyan Holiday",
          "types": "National Holiday",
          "day": "Monday",
          "date": "14 April 2025",
          "comments": "Water festival"
        },
        {
          "name": "Thingyan Holiday",
          "types": "National Holiday",
          "day": "Tuesday",
          "date": "15 April 2025",
          "comments": "Water festival"
        },
        {
          "name": "Thingyan Holiday",
          "types": "National Holiday",
          "day": "Wednesday",
          "date": "16 April 2025",
          "comments": "Water festival"
        },
        {
          "name": "Burmese New Year",
          "types": "Government Holiday",
          "day": "Thursday",
          "date": "17 April 2025",
          "comments": "--"
        },
        {
          "name": "Labour Day",
          "types": "National Holiday",
          "day": "Thursday",
          "date": "1 May 2025",
          "comments": "May Day"
        },
        {
          "name": "Full Moon Day of Kasong",
          "types": "National Holiday",
          "day": "Sunday",
          "date": "11 May 2025",
          "comments": "Birth of Buddha"
        },
        {
          "name": "Eid ul-Adha Day",
          "types": "National Holiday",
          "day": "Saturday",
          "date": "7 June 2025",
          "comments": "Date subject to change"
        },
        {
          "name": "Full Moon Day of Waso",
          "types": "National Holiday",
          "day": "Thursday",
          "date": "10 July 2025",
          "comments": "Dhammasetkya Day. Beginning of Buddhist Lent"
        },
        {
          "name": "Martyr's Day",
          "types": "National Holiday",
          "day": "Saturday",
          "date": "19 July 2025",
          "comments": "--"
        },
        {
          "name": "Full Moon Day of Thadingyut",
          "types": "National Holiday",
          "day": "Sunday",
          "date": "5 October 2025",
          "comments": "End of Buddhist Lent"
        },
        {
          "name": "Full Moon of Thadingyut Holiday",
          "types": "National Holiday",
          "day": "Monday",
          "date": "6 October 2025",
          "comments": "End of Buddhist Lent"
        },
        {
          "name": "Full Moon of Thadingyut Holiday",
          "types": "National Holiday",
          "day": "Tuesday",
          "date": "7 October 2025",
          "comments": "End of Buddhist Lent"
        },
        {
          "name": "Deepavali",
          "types": "National Holiday",
          "day": "Tuesday",
          "date": "21 October 2025",
          "comments": "Date subject to change" 
        },
        {
          "name": "Day before full moon day of Tazaungmone",
          "types": "National Holiday",
          "day": "Monday",
          "date": "3 November 2025",
          "comments": "--"
        },{
          "name": "Full Moon of Tazaungmone",
          "types": "National Holiday",
          "day": "Tuesday",
          "date": "4 November 2025",
          "comments": "--"
        },
        {
          "name": "National Day",
          "types": "National Holiday",
          "day": "Saturday",
          "date": "15 November 2025",
          "comments": "--"
        },
        {
          "name": "Christmas Day",
          "types": "National Holiday",
          "day": "Thursday",
          "date": "25 December 2025",
          "comments": "--"
        },
        {
          "name": "Christmas Holiday",
          "types": "National Holiday",
          "day": "Friday",
          "date": "26 December 2025",
          "comments": "--"
        },
        {
          "name": "New Year",
          "types": "National Holiday",
          "day": "Wednesday",
          "date": "31 December 2025",
          "comments": "--"
        }
      ]
    }
  }
  
  export default Holidaydata;
  